import { CustomersLogo } from './Customers.type'

export const logos: Array<CustomersLogo> = [
  {
    id: '1',
    title: 'bandnewsfmgoiania',
    url: 'bandnewsfmgoiania.png'
  },
  {
    id: '2',
    title: 'coco-bambu-restaurante',
    url: 'coco-bambu-restaurante.png'
  },
  {
    id: '3',
    title: 'coming',
    url: 'coming.png'
  },
  {
    id: '4',
    title: 'curtidoratocantins',
    url: 'curtidoratocantins.png'
  },
  {
    id: '5',
    title: 'drogariasantamarta',
    url: 'drogariasantamarta.png'
  },
  {
    id: '6',
    title: 'flamboyant',
    url: 'flamboyant.png'
  },
  {
    id: '7',
    title: 'fresenius-kabi',
    url: 'fresenius-kabi.png'
  },
  {
    id: '8',
    title: 'fujioka',
    url: 'fujioka.png'
  },
  {
    id: '9',
    title: 'grupo-jorlan',
    url: 'grupo-jorlan.png'
  },
  {
    id: '10',
    title: 'grupo-natureza-o-boticario',
    url: 'grupo-natureza-o-boticario.png'
  },
  {
    id: '11',
    title: 'grupocereal',
    url: 'grupocereal.png'
  },
  {
    id: '12',
    title: 'igreja-luz',
    url: 'igreja-luz.png'
  },
  {
    id: '13',
    title: 'lifebox',
    url: 'lifebox.png'
  },
  {
    id: '14',
    title: 'limagrain-vector-logo',
    url: 'limagrain-vector-logo.png'
  },
  {
    id: '15',
    title: 'luidoices',
    url: 'luidoices.png'
  },
  {
    id: '16',
    title: 'mega-moda-park',
    url: 'mega-moda-park.png'
  },
  {
    id: '17',
    title: 'novomundo',
    url: 'novomundo.png'
  },
  {
    id: '18',
    title: 'piquiras',
    url: 'piquiras.png'
  },
  {
    id: '19',
    title: 'richesse',
    url: 'richesse.png'
  },
  {
    id: '20',
    title: 'teashop',
    url: 'teashop.png'
  },
  {
    id: '21',
    title: 'teuto',
    url: 'teuto.png'
  },
  {
    id: '22',
    title: 'triunfoconcebra',
    url: 'triunfoconcebra.png'
  }
]
