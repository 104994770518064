import { useCallback, useEffect, useState } from 'react'

import { NumberContext } from '@/components/Numbers/Numbers.type'
import { getNumbers } from '@/services'
import { RequestStatus } from '@/types'

type DataStructure = {
  title?: string
  subtitle?: string
}

function useGetNumbersHome(context: NumberContext) {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Idle)
  const [data, setData] = useState<DataStructure[]>()

  const handle = useCallback(async () => {
    if (status === RequestStatus.Idle) {
      setStatus(RequestStatus.Pending)

      try {
        const res = await getNumbers(context)

        if (!res) {
          setStatus(RequestStatus.Rejected)
          return false
        }

        const keys = Object.keys(res)

        const filteredKeys = keys.filter(
          (key) => key.includes('Titulo') || key.includes('Subtitulo')
        )

        const mappedData = filteredKeys.sort().reduce<DataStructure[]>((result, key, index) => {
          const isTitleKey = key.includes('Titulo')
          const objectIndex = Math.floor(index / 2)

          if (isTitleKey || key.includes('Subtitulo'))
            result[objectIndex] = result[objectIndex] || { title: undefined, subtitle: undefined }

          const obj: DataStructure = result[objectIndex] as DataStructure
          if (isTitleKey) {
            obj.title = res[key]
          } else {
            obj.subtitle = res[key]
          }

          return result
        }, [] as DataStructure[])

        setStatus(RequestStatus.Resolved)
        setData(mappedData)
      } catch (e) {
        setStatus(RequestStatus.Rejected)
      }
    }
    return null
  }, [])

  useEffect(() => {
    handle().then()
  }, [])

  return { status, data }
}

export { useGetNumbersHome }
