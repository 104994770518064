import React from 'react'

import { Navbar } from '@/components'

const Header: React.FC = () => (
  <header>
    <Navbar />
  </header>
)

export default Header
