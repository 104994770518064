export const socialLinks: Array<{
  name: string
  icon: string
  url: string
}> = [
  {
    name: 'Instagram',
    icon: '/img/icons/instagram.svg',
    url: 'https://www.instagram.com/grupobcenergia/'
  },
  {
    name: 'Facebook',
    icon: '/img/icons/facebook.svg',
    url: 'https://www.facebook.com/GrupoBCEnergia'
  },
  {
    name: 'Linkedin',
    icon: '/img/icons/linkedin.svg',
    url: 'https://www.linkedin.com/company/grupobcenergia/'
  },
  {
    name: 'YouTube',
    icon: '/img/icons/youtube.svg',
    url: 'https://www.youtube.com/@grupobcenergia'
  }
]
