import { useEffect, useState } from 'react'

import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { NavbarItems } from '../../Navbar.type'
import { SubMenu } from '../SubMenu'
import { menuItemStyle } from './MenuItem.style'

const MenuItem = ({ ...item }: NavbarItems) => {
  const pathname = usePathname()
  const [isMenuOpen, setMenuOpen] = useState(false)

  useEffect(() => setMenuOpen(false), [pathname])

  const isActiveSubItem = item.subItems?.find(
    (subItem) => pathname.startsWith(subItem.url) || pathname === subItem.url
  )

  const isItemActive = isActiveSubItem || pathname === item.url
  // TODO: Resolver problema de hidratação com div dentro do link
  return (
    <div
      className="inline"
      key={item.id}
      onMouseEnter={() => setMenuOpen(true)}
      onMouseLeave={() => setMenuOpen(false)}
    >
      <Link
        href={item.url}
        target={item.target || '_self'}
        className={menuItemStyle({ active: !!isItemActive })}
      >
        {item.title}
        {item.subItems?.length ? <SubMenu key={item.id} isMenuOpen={isMenuOpen} {...item} /> : null}
      </Link>
    </div>
  )
}

export default MenuItem
